(<template>
  <article class="payout is-progress-bar">
    <div class="payout__main">
      <div v-for="(payoutItem, index) in filteredPayoutColumns"
           :key="index"
           class="payout__column">
        <p v-if="payoutItem.name"
           class="payout__method-label">{{ payoutItem.name }}</p>
        <p class="payout__method-info">{{ payoutItem.value }}</p>
      </div>
    </div>
    <div class="payout__options">
      <sk-checkbox v-if="isDefaultPayout"
                   :item-text="payoutData.default ? isDefaultText : setDefaultText"
                   :disabled="payoutData.default"
                   :preselected-value="payoutData.default"
                   @checkboxvaluechanged="setDefault" />
      <button v-if="canDeletePayout"
              class="sk-btn sk-btn--like-link sk-link sk-link--white-bg payout__del-btn js-payout-delete"
              @click="confirmRemoval(payout.id, payout.type)">{{ deleteText }}</button>
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      payout: {
        type: Object,
        default() {
          return {};
        }
      },
      isDefaultPayout: Boolean
    },
    computed: {
      detailsText() { return this.$gettext('Details'); },
      isDefaultText() { return this.$gettext('Default method'); },
      setDefaultText() { return this.$gettext('Set as a default'); },
      deleteText() { return this.$gettext('Delete method'); },
      payoutData() { return this.payout || {}; },
      payoutTypeText() {
        switch (this.payoutData.type) {
          case 'payrolls':
            return this.$gettextInterpolate(this.$gettext('%{domain}\'s Payroll services'), {domain: this.$getString('domainData', 'platformName')});
          case 'dipps':
            return this.$gettext('PayoutPartner account');
          default:
            return '';
        }
      },
      companyText() {
        const commonTemplate = this.$gettext('I use my own provider and %{companyText}');
        return this.payoutData.has_company
          ? this.$gettextInterpolate(commonTemplate, {companyText: this.$gettext('have a company')})
          : this.$gettextInterpolate(commonTemplate, {companyText: this.$gettext('don\'t have a company')});
      },
      supplierNumber() {
        return this.isDefaultPayout ? this.payoutData.supplier_number : this.payoutData.locked?.supplier_number;
      },
      supplierNumberText() {
        switch (+this.supplierNumber) {
          case 1:
            return this.$gettext('Self-invoicing with EHF');
          case 2:
            return this.$gettext('Please contact us at regnskap@salita.no');
          case 3:
            return this.$gettext('Free salary service via Salita');
          case 4:
            return this.$gettext('Free invoicing service via Salita');
          case 5:
            return this.$gettext('Salary service agreement with Salita');
          default:
            return '';
        }
      },
      canChangeDefaultPayout() {
        return !this.payoutData.locked;
      },
      canDeletePayout() {
        return this.canChangeDefaultPayout && this.payoutData.type !== 'dipps' && !this.payoutData.default;
      },
      payoutColumns() {
        return [{
          name: this.$gettext('Type'),
          value: this.payoutTypeText,
          condition: this.payoutTypeText
        }, {
          name: '',
          value: this.companyText,
          condition: this.payoutData.has_company !== undefined
        }, {
          name: this.$gettext('Name'),
          value: `${this.payoutData.first_name} ${this.payoutData.last_name}`,
          condition: this.payoutData.first_name && this.payoutData.last_name
        }, {
          name: this.$gettext('Org. number'),
          value: this.payoutData.org_number,
          condition: this.payoutData.org_number
        }, {
          name: this.$gettext('Personal number'),
          value: this.payoutData.personal_number,
          condition: this.payoutData.personal_number
        }, {
          name: this.$gettext('Bank account number'),
          value: this.payoutData.bank_account_num,
          condition: this.payoutData.bank_account_num
        }, {
          name: `Supplier number: ${this.supplierNumber}`,
          value: this.supplierNumberText,
          condition: this.supplierNumber
        }, {
          name: this.$gettext('Locked for'),
          value: this.payoutData.locked ? this.getLockedDate(this.payoutData.locked) : '',
          condition: !this.isDefaultPayout
        }];
      },
      filteredPayoutColumns() {
        return this.payoutColumns.filter(({condition}) => condition);
      }
    },
    methods: {
      confirmRemoval(id, type) {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirm-payout-removal',
          data: {
            title: this.$gettext('Remove payout?'),
            data: {id, type},
            confirm: this.deletePayout
          }
        });
      },
      getLockedDate({month, year}) {
        return `${month}/${year}`;
      },
      setDefault(value) {
        if (!this.payoutData.default && value) {
          this.$emit('setdefaultpayout', this.payoutData.id, this.payoutData.type);
        }
      },
      deletePayout(id, type) {
        this.$emit('deletepayout', id, type);
      }
    }
  };
</script>

<style scoped>
  .payout {
    margin-bottom: 20px;
  }

  .payout:last-child {
    margin-bottom: 0;
  }

  .payout__options {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }

  .payout__options .sk-checkbox {
    width: auto;
    max-width: 300px;
    text-wrap: normal;
  }

  .payout__options .sk-link {
    font-weight: normal;
  }

  .payout__main {
    display: flex;
    padding: 15px 10px 15px 20px;
    border: solid 1px #ff5b24;
    border: solid 1px var(--color-secondary);
    border-radius: 3px;
    background-color: #ccf6e4;
  }

  .payout__column {
    flex-grow: 1;
    width: calc(23% - 10px);
    padding: 0 5px;
    text-align: start;
  }

  .payout__method-label {
    font-size: 10px;
  }

  .payout__method-info {
    font-size: 16px;
    word-wrap: break-word;
  }

  .payout__del-btn {
    margin-left: auto;
  }

  .sk-checkbox.is-disabled {
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    .payout__main {
      flex-direction: column;
    }

    .payout__column {
      width: 100%;
    }

    .payout__column:not(:last-of-type) {
      padding-bottom: 20px;
    }
  }
</style>
